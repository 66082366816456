<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader> <strong> Forwarder </strong> Information </CCardHeader>
            <CCardBody>
              <CForm>
                <CInput label="Name" 
                v-model="$v.obj.name.$model" 
                :isValid="checkIfValid('name')"
                invalidFeedback="This is a required field"
                horizontal/>
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    Address
                  </CCol>
                  <CCol sm="9">
                    <CTextarea
                      placeholder="Address..."
                      rows="5"
                      v-model="obj.address"
                    />
                  </CCol>
                </CRow>
                <CInput label="Phone" v-model="obj.phone" horizontal />
                <CInput label="Country" v-model="obj.country" horizontal />
                <CInput label="Website" 
                v-model="obj.website" 
                invalidFeedback="This is a required field"
                horizontal />
                <CInput
                  label="Contact Person Name"
                  v-model="obj.contactPersonName"
                  horizontal
                />
                <CInput
                  label="Contact Person Phone"
                  v-model="obj.contactPersonPhone"
                  horizontal
                />
                <CInput
                  label="Contact Person Email"
                  v-model="obj.contactPersonEmail"
                  horizontal
                />
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" 
              size="sm" 
              color="primary" 
              :disabled="!isValid"
              @click="onSubmit"
                ><CIcon name="cil-check-circle" /> Submit</CButton
              >

              <CButton class="ml-1" color="secondary" @click="cancel">
                Cancel
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import ForwarderApi from "../../lib/forwarderApi";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "Forwarder",
  data: () => {
    return {
      infoList: [],
      api: new ForwarderApi(),
      obj: {
        name: "",
      },
      submitted: false,
    };
  },
  mounted() {
    var self = this;
    this.obj = this.getEmptyObj();
    self.resetObj();
  },

  validations: {
    obj: {
      name: {
        required,
        minLength: minLength(2),
      },
     
    },
  },


  computed: {
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    isValid() {
      return !this.$v.obj.$invalid;
    },
    isDirty() {
      return this.$v.obj.$anyDirty;
    },
  },


  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.obj[fieldName];
      return !(field.$invalid || field.$model === "");
    },
    onSubmit() {
      var self = this;
      if (!self.obj.id) {
        this.api
          .create(self.obj)
          .then((response) => {
            self.$router.push({ path: "/admins/ForwarderList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .update(self.obj)
          .then((response) => {
            self.$router.push({ path: "/admins/ForwarderList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resetObj() {
      var self = this;
      if (self.$route.params.id) {
        this.api
          .get(self.$route.params.id)
          .then((response) => {
            self.obj = response.result;
            console.log(self.obj);
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        self.obj = self.getEmptyObj();
      }
    },

    getEmptyObj() {
      return {
        name: "",
        address: "",
        country: "",
        phone: "",
        website: "",
        contactPersonName: "",
        contactPersonPhone: "",
        contactPersonEmail: "",
      };
    },
    cancel() {
      this.$router.push({ path: "/admins/ForwarderList" });
    },
  },
};
</script>
